<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')" presistent>
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ data.name ? data.name : "Новая запись" }}
      </template>
      <div v-if="data.status == -3" style="color: red">Ошибка при создании задачи. обратитесь к администратору базы данных</div>
      <a-loader v-if="loading" />
      <div class="px-0 pt-1" v-if="!loading">
        <v-layout px-2 py-2 row wrap>
          <v-flex px-1 xs9 sm-pb-0>
            <a-input-selectroom
              class="pt-1"
              :items="roomList"
              :model="getFormModel(['data.rooms'])[0]"
              v-model="data.data.rooms"
              :config="{ dense: true, readonly, chips: true }"
              @input="
                onSelectRooms($event);
                validate($event);
              "
              :error="errors['data.rooms']"
            />
          </v-flex>
          <v-flex px-1 xs3 sm-pb-0>
            <a-form-model v-model="data" :model="getFormModel(['isFurniture'])" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
          </v-flex>
          <v-flex px-1 xs12 sm-pb-0>
            <a-form-model
              v-model="data"
              :model="getFormModel(['goods_type_id'])"
              :errors="errors"
              :config="{ dense: true }"
              @selected="onSelectGoodsType($event)"
              @validate="validate($event)"
            />
          </v-flex>
          <a-form-modelCols
            v-model="data"
            class="pb-3 px-3"
            :model="getFormModel('data.room_class#3,data.price_comfort#3,data.price_business#3,data.price_premium#3,category_id,name,vendor,article'.split(','))"
            :values="data"
            :errors="errors"
            :config="{ dense: true, readonly, hideDetails: 'auto' }"
            @validate="validate($event)"
          />

          <v-flex px-1 xs3 sm-pb-0>
            <a-input-number
              class="pt-1"
              :model="getFormModel(['amount'])[0]"
              v-model="data['amount']"
              :config="{ dense: true, readonly }"
              @input="validate($event)"
              :error="errors['amount']"
            />
          </v-flex>
          <v-flex px-1 xs3 sm-pb-0 mt-1>
            <a-form-model v-model="data" :model="getFormModel(['measure'])" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
          </v-flex>
          <v-flex px-1 xs3 sm-pb-0>
            <a-input-number
              class="pt-1"
              :model="{ name: 'price_unit', title: 'цена за ед.' }"
              v-model="price_unit"
              :config="{ dense: true, readonly }"
              @input="validate($event)"
              :error="errors['price_unit']"
            />
          </v-flex>
          <v-flex px-1 xs3 sm-pb-0>
            <a-input-number
              class="pt-1"
              :model="getFormModel(['price'])[0]"
              v-model="data['price']"
              :config="{ dense: true, readonly }"
              @input="validate($event)"
              :error="errors['price']"
            />
          </v-flex>
        </v-layout>
        <a-form-model v-model="data" :model="getFormModel(['date_buy'])" :errors="errors" :config="{ dense: true, readonly }" @validate="validate($event)" />
        <a-form-model
          v-model="data"
          :model="getFormModel(['description', 'link', 'photo'])"
          :errors="errors"
          :config="{ dense: true, readonly }"
          @validate="validate($event)"
        />
      </div>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="preSubmit()" v-if="editObject && data.status != -3" :disabled="data.status === 0"></a-btn-save>
        <a-btn-delete v-if="id && !data.task_id && editObject && data.status != -3" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="_remove(api, id)" :title="removeTitle" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, modelOptions } from "../../components/mixings";

export default {
  components: {
    //  usersDialog: () => import("./usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess, modelOptions],
  props: {
    value: Boolean,
    id: Number,
    object_: { type: Object, default: () => {} },
    defaults: { type: Object, default: () => {} },
  },
  data() {
    return {
      model: this.$store.getters["config/get"].models.goods.form,
      api: "/mechti/construction-goods",
      price_unit: null,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить запись из чек-листа?",
      readonly: false,
      loading: false,
      idEdit: 0,
      suEditShow: false,
    };
  },
  created() {
    let api = this.getFormModel(["room_type"])[0].api;
    if (!api.includes("filters")) this.getFormModel(["room_type"])[0].api += this.filters;
  },
  computed: {
    rooms_classes() {
      return this.getOptions({ options: "rooms_classes" }) || [];
    },
    roomList() {
      let res = [];
      if (this.object_?.data?.rooms) res = this.object_.data.rooms;
      res.forEach((r) => {
        const c = this.rooms_classes.find((f) => {
          return f.value == r.room_class;
        });
        r.color = c?.color;
        r.class_name = c?.text || null;
      });
      return res;
    },
    editObject() {
      return this.getAccess("object.goodsEdit", {
        id: this.object_.id,
        access: this.object_?.object_access || [],
      });
    },
    filters() {
      return `?filters=${JSON.stringify({ object_id: this.object_.id })}`;
      return `?filters=%7B%22object_id%22:${this.object_.id}%7D`;
    },

    photo: {
      get() {
        let res = this.data?.data?.photo || [];
        return res;
      },
      set(v) {
        if (!this.data.data?.photo) this.data.data = Object.assign({}, this.data.data, { photo: [] });
        this.data.data.photo = v;
      },
    },
  },
  watch: {
    value() {
      ///не задействуется если в вызове компонента стоит v-if
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) {
          this.data.object_id = this.object_.id;
          this.data.date_buy = new Date().date;
          //  this.data.status = 0;
        }
      }
    },
    price_unit() {
      this.calcPrice(1);
    },
    "data.amount"() {
      this.calcPrice(1);
    },
  },
  methods: {
    onSelectGoodsType(e) {
      this.data.data.price_business = e.value?.price_business;
      this.data.data.price_comfort = e.value?.price_comfort;
      this.data.data.price_premium = e.value?.price_premium;
      this.data.category_id = e.value?.category_id;
    },
    onSelectRooms(e) {
      if (!e) return;
      const rooms = e.map((el) => el.name).join(", ");
      const room_class = Math.min(
        ...e.map(
          (el) =>
            this.rooms_classes.find((f) => {
              return f.value == el.room_class;
            })?.class || 0
        )
      );

      this.data.room_type = rooms;
      //this.data.data.room_class = e[0]?.room_class || null;
      this.data.data.room_class =
        this.rooms_classes.find((f) => {
          return f.class == room_class;
        })?.value || null;
    },
    resetForm() {
      this.price_unit = null;
    },
    afterFetchData() {
      this.calcPrice(0);
    },
    calcPrice(v) {
      if (v) this.data.price = this.price_unit * this.data.amount || null;
      else this.price_unit = this.data.price / this.data.amount;
    },
    setVendor(link) {
      if (!link) link = this.data.data.link;
      if (!link) return;

      if (link) this.data.vendor = link.replace("www.", "//").split(/\/+/)[1];
    },
    preSubmit() {
      if (!this.data.object_id) {
        this.$root.$emit("show-info", {
          text: "Возникла ошибка... перезагрузите форму",
          type: "error",
        });
        return;
      }
      if (!this.data.vendor) {
        this.setVendor(this.data.data.link);
      }
      let status = this.data.status;
      let check;
      let model = this.$store.getters["config/get"].models.goods.form;
      if (status < 0 && status != -3) {
        status = -1;
        model.forEach((el) => {
          switch (typeof el.check) {
            case "boolean":
              check = el.check;
              break;
            case "function":
              check = el.check(this.data.data.isFurniture);
              break;
            default:
              check = false;
          }
          //check = el?.check === undefined ? false : typeof el.check == 'function'
          let data;
          if (el.name.includes(".")) {
            let path = el.name.split(".");
            data = this.data?.[path[0]]?.[path[1]];
          } else data = this.data.data?.[el.name] || this.data?.[el.name];
          if (check === true && status == -1 && !data) {
            status = -2;
          }
          this.data.status = status;
        });
      }
      this.submit();
    },
    _remove(api, id) {
      if (this.data.status == 0) {
        this.$root.$emit("show-info", {
          text: "Задача уже создана. Удаление невозможно",
          type: "error",
        });

        return;
      }
      this.remove(api, id);
    },
    beforeSave() {},
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
